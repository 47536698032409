<template>
  <div class="page not-found">
    <h1>
      {{ $t('notFound.title') }}
    </h1>
    <p>{{ $t('notFound.message') }}</p>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>
